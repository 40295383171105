import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/info";
export const PageQuery_info_nl_affiliatesmdx = graphql`
        query PageQuery_info_nl_affiliatesmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "info"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/affiliates/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Affiliate Programma",
  "path": "/info/affiliates",
  "date": "2022-09-29T00:00:00.000Z"
};
const layoutProps = {
  PageQuery_info_nl_affiliatesmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "affiliate-programma",
      "style": {
        "position": "relative"
      }
    }}>{`Affiliate Programma`}<a parentName="h1" {...{
        "href": "#affiliate-programma",
        "aria-label": "affiliate programma permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <blockquote>
      <p parentName="blockquote">{`Verdien een extra inkomen door het promoten van Cheesebyte producten, met een vergoeding voor elke nieuwe verwijzing.`}</p>
    </blockquote>
    <h2 {...{
      "id": "hoe-het-werkt",
      "style": {
        "position": "relative"
      }
    }}>{`Hoe het werkt`}<a parentName="h2" {...{
        "href": "#hoe-het-werkt",
        "aria-label": "hoe het werkt permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`Promoot via uw best presterende kanalen, zoals sociale netwerken, blog posts of podcast afleveringen`}</li>
      <li parentName="ul">{`Ontvang een jaar lang tot 20% terugkerende vergoeding voor elke nieuwe doorverwezen klant`}</li>
      <li parentName="ul">{`Met behulp van een unieke link krijgt de doorverwezen partij 35% korting in de eerste maand`}</li>
      <li parentName="ul">{`Top affiliates krijgen de kans om prestatieafhankelijke bonussen te ontvangen en meer te verdienen`}</li>
      <li parentName="ul">{`Gratis ondersteuning vanuit Cheesebyte met ontwerpen en ideeën om gepromote producten te laten opvallen`}</li>
    </ul>
    <h1 {...{
      "id": "aanmelden-voor-het-programma",
      "style": {
        "position": "relative"
      }
    }}>{`Aanmelden voor het programma`}<a parentName="h1" {...{
        "href": "#aanmelden-voor-het-programma",
        "aria-label": "aanmelden voor het programma permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <blockquote>
      <p parentName="blockquote">{`Vertel iets over hoe u het product zou promoten (zie 'Producten' op de navigatiebalk voor de beschikbare opties) en stuur dit via `}<a parentName="p" {...{
          "href": "mailto:hello@cheesebyte.nl?subject=Affiliate"
        }}>{`een e-mail`}</a>{`. U ontvangt binnen 3 werkdagen een antwoord.`}</p>
    </blockquote>
    <h2 {...{
      "id": "meer-informatie",
      "style": {
        "position": "relative"
      }
    }}>{`Meer informatie`}<a parentName="h2" {...{
        "href": "#meer-informatie",
        "aria-label": "meer informatie permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Voor een goede afhandeling van uw bericht wordt u verzocht de volgende gegevens te vermelden:`}</p>
    <ul>
      <li parentName="ul">{`Uw volledige naam`}</li>
      <li parentName="ul">{`Promotiemethoden`}</li>
      <li parentName="ul">{`E-mail of andere contactgegevens`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      